import { GetStaticPropsResult } from 'next';

import { REVALIDATE } from '../../src/constants/next';
import { IPageMetadata } from '../../src/interfaces/nextjs';
import { ShopLandingPage } from '../../src/views/ShopLandingPage/ShopLandingPage';

export async function getStaticProps(): Promise<GetStaticPropsResult<IPageMetadata>> {
  return {
    props: {
      _page: 'shop',
      _pageTitle: 'Shop',
    },
    revalidate: REVALIDATE.DAILY,
  };
}

export default ShopLandingPage;
